// @flow
import './index.less';

import { Empty } from 'antd';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

const NotFound = (): React$Node => {
  const { t } = useTranslation();

  return (
    <Empty
      style={{
        margin: '6rem auto',
        height: '100vh',
      }}
      description={
        <div className="not-found">
          <div>{t('Common_PageNotFound')}</div>
          <div>{t('PageNotFound_Description')}</div>
          <Link to="/">{t(`Common_BackToHome`)}</Link>
        </div>
      }
    />
  );
};

export default NotFound;
