// @flow
import { useLocation } from '@reach/router';

import React, { useEffect } from 'react';

import NotFound from '_components/NotFound';
import useAnalytics, { event } from '_hooks/useAnalytics';

const NotFoundPage = (): React$Node => {
  const { track } = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    track({
      group: event.group.NotFound,
      action: event.action.Viewed,
      data: {
        path: location.pathname,
        origin: location.origin,
      },
    });
  }, [track, location]);

  return <NotFound />;
};

export default NotFoundPage;
